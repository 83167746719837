<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in menus"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from 'vue'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  computed: {
    menus() {
      const menus = {
        'Admin::User': [
          {
            title: 'Cadastro da operação',
            icon: 'FilePlusIcon',
            route: 'emissions.new',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Gestão de remessas',
            icon: 'SettingsIcon',
            route: 'remessas.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Gestão de redesconto',
            icon: 'TagIcon',
            route: 'redescontos.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Gestão de títulos',
            icon: 'CustomFileCertificateIcon',
            route: 'titulos.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Gestão de extrato',
            icon: 'CustomReportMoneyIcon',
            route: 'extract.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Caixa',
            icon: 'DollarSignIcon',
            route: 'cashier.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Usuários',
            icon: 'UsersIcon',
            route: 'users.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Arquivos de retorno',
            icon: 'CustomReturnFilesIcon',
            route: 'returnfiles.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Arquivos de exceção',
            icon: 'FileTextIcon',
            route: 'exceptionfiles.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Relatórios',
            icon: 'FileIcon',
            route: 'reports.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Baixar lastros',
            icon: 'DownloadIcon',
            route: 'lastros.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Swap',
            icon: 'ShieldIcon',
            route: 'swap.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
          // {
          //   header: 'Cadastros',
          //   action: 'admin-read',
          //   resource: 'admin-user',
          // },
        ],
        'Parceiro::User': [
          {
            title: 'Remessas',
            icon: 'SettingsIcon',
            route: 'partner.remessas.index',
            action: 'partner-read',
            resource: 'partner-user',
          },
          {
            title: 'Redescontos',
            icon: 'TagIcon',
            route: 'partner.redescontos.index',
            action: 'partner-read',
            resource: 'partner-user',
          },
          {
            title: 'Títulos',
            icon: 'CustomFileCertificateIcon',
            route: 'partner.titulos.index',
            action: 'partner-read',
            resource: 'partner-user',
          },
          {
            title: 'Ocorrências',
            icon: 'CustomReportMoneyIcon',
            route: 'partner.extract.index',
            action: 'partner-read',
            resource: 'partner-user',
          },
          {
            title: 'Arquivos de exceção',
            icon: 'FileTextIcon',
            route: 'exceptionfiles.index',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Chaves de API',
            icon: 'KeyIcon',
            route: 'partner.apiKeys',
            action: 'partner-read',
            resource: 'partner-user',
          },
        ],
      }

      return menus[this.$store.state.auth.userData.type]
    },
  },
}
</script>
